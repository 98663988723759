import { getRandomSizeCat } from './catUtil';
import { getRandomInRange } from './mathUtil';

export default { getRandomSizeCat, getRandomInRange };


//utils/index.js:  

// export { default as DateUtils} from './DateUtils';
// export { default as StringUtils} from './StringUtils';
// export { default as NumberUtils} from './NumberUtils';

// import { DateUtils, NumberUtils} from '../utils ';

// import * as utils from '../utils ';